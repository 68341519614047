import React from "react";
import { useUser } from "../../context/UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import VerifiedImg from "../../../assets/images/Verified.svg";
import "./Verified.scss";
const Verify = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogin = () => {
    // Perform login logic here

    // Redirect back to the original page
    const redirectTo = location.state?.from || "/"; // Default to home if no redirect path is saved
    navigate(redirectTo);
  };
  return (
    <div className="background-verified-page pt-[220px] ">
      <div className="mx-auto    flex items-center justify-center h-screen    ">
        <div className="w-[auto] ">
          <div className="    background-verfied">
            <div>
              <img src={VerifiedImg} alt="" />
            </div>
            <div className="info-verified  absolute ">
              <p className="font-black my-[47px] text-center text-[20px] w-[461px]">
                مرحبا بك ،{user.name}
              </p>
              <h1 className="font-black text-center  text-[2rem]">
                عضويتك مفعله بنجاح
              </h1>
            </div>
            {/* <div className="absolute bottom-0   ">
           
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
