import React, { useEffect, useState } from "react";
import config from "../Services/config";
import useApi from "../hooks/useApi";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import faq from "../../assets/images/faq.svg";
import "./FaqAccordion.scss";
const All = () => {
  //toggle the accordion
  const handleToggle = (index) => {
    // console.log(index);
    const newIsOpenArray = isOpenArray.map(() => false); // Close all accordions
    newIsOpenArray[index] = !isOpenArray[index]; // Toggle the clicked accordion
    setIsOpenArray(newIsOpenArray);
  };
  const baseUrl = config.apiUrl;
  const [isOpenArray, setIsOpenArray] = useState([]);

  const { data, fetchData, Loading } = useApi();
  useEffect(() => {
    const Url = `${baseUrl}/support-category-questions`;
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        lang: "ar",
      },
    };
    fetchData(Url, options);
  }, []);
  //   console.log(data);
  return (
    <>
      <h1 className="text-center text-[24px] font-extrabold  mt-[150px] mb-8">
        اسئلة شائعة
      </h1>
      <div className="xl:w-[1200px] md:w-[800px] w-[400px] mx-auto lg:flex ">
        <div className="md:w-[50%] flex mx-auto w-full lg:hidden ">
          <img src={faq} alt="" />
        </div>
        <div className="  mx-auto lg:grid lg:grid-cols-2 gap-2  lg:w-[60%] w-full">
          {" "}
          {data?.body?.map((q, index) => (
            <div
              key={q.answer}
              className={`accordion ${
                isOpenArray[index] ? "accordion-open  " : ""
              } py-4 px-2`}
            >
              <div
                className="accordion-header flex"
                onClick={() => handleToggle(index)}
              >
                <div>
                  <button className="btn-container ml-2">
                    {isOpenArray[index] ? (
                      <AiOutlineMinus className="accordion-btn text-white" />
                    ) : (
                      <AiOutlinePlus className="accordion-btn text-white" />
                    )}
                  </button>{" "}
                </div>
                <div>
                  <span className="font-bold mt-1">{q.question}</span>
                </div>
              </div>
              {isOpenArray[index] && (
                <div className="accordion-content  ">
                  <p>{q.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="md:w-[50%] w-full hidden lg:block ">
          <img src={faq} alt="" />
        </div>
      </div>
    </>
  );
};

export default All;
