import React from "react";
import { Link } from "react-router-dom";
import "./Layout.scss";
const Header = ({ name }) => {
  return (
    <div className="   header">
      <div className="xl:pr-[160px] pt-[40px] pr-[20px] h-[122px] lg:h-[296px] ">
        <h1 className="text-[18px] lg:text-[4rem] text-[#fff] font-extrabold ">
          {" "}
          {name}{" "}
        </h1>
        <Link to="/">الرئيسية | </Link>
        <Link className=""> {name} </Link>
      </div>
    </div>
  );
};

export default Header;
