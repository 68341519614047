import React, { useEffect } from "react";
import Header from "../../../../Layout/Header";
import "./success.scss";
// import logo from "../../../../../assets/images/image 91.svg";
import { useLocation } from "react-router-dom";

import { SlLocationPin } from "react-icons/sl";
import { CiPhone } from "react-icons/ci";
import config from "../../../../Services/config";
const DiscountOrderSuccess = () => {
  const baseUrl = config.apiUrl;
  const location = useLocation();
  const { offerData } = location.state || {};
  const data = offerData.body;
  // console.log(offerData);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Add offerId here
  //handel pdf

  //handel call
  // const handleCall = (e) => {
  //   e.preventDefault();
  //   window.location.href = "tel:+1234567890";
  // };
  const firstAddress = data?.offer?.center?.addresses[0].address;
  const phone = data?.offer?.center?.addresses[0].phone;
  return (
    <div className="mb-[100px]">
      <Header name={"العروض والكوبونات"} />

      <h1 className="text-[14px] md:text-[24px] text-center font-extrabold  mt-[70px]  ">
        تم ارسال العرض الى بريدك الإلكتروني بنجاح
      </h1>
      {/* <div className="flex justify-center lg:mt-[72px] ">
        <img src={successCoupon} alt="" />
      </div> */}
      <div className=" mx-auto w-[400px] xl:w-[700px] my-8  px-[20px] py-[12px] coupon-success">
        {/* <div className="flex  align-center justify-center pt-[10px] lg:pt-[30px] ">
          <img className="w-[320px] h-[225px]" src={successCoupon} alt="" />
        </div> */}
        {/* <h1 className="text-[16px] md:text-[24px] text-center font-extrabold  mb-4 lg:mb-[50px]">
          تم ارسال الكوبون الى بريدك الالكتروني بنجاح
        </h1> */}
        <div className="flex justify-between mb-4 pb-[20px] ">
          <div className="flex">
            <img
              className="w-[120px] h-[120px] ml-4"
              src={data?.offer?.center?.logo}
              alt=""
            />
            <p className="mt-[30px] text-[#41B6C3] text-[16px] md:text-[20px] font-bold">
              {data?.offer?.center?.name}
            </p>
          </div>
          <div className="text-center mt-[20px] hidden md:block">
            <p className="text-[#30ECAC] font-bold text-[12px] md:text-[20px]">
              {data?.offer?.days_left}
            </p>
            <div className="md:bg-[#F4FCFE] md:p-2 rounded-xl text-[12px] md:text-[16px]">
              <p className="text-[#8591A3] ">
                تاريخ الانتهاء {data?.offer?.expire_date}
              </p>
            </div>
          </div>
        </div>
        {/* for mobile view */}
        <div className=" mt-[20px] md:hidden flex justify-between items-center my-4">
          <p className="text-[#30ECAC] font-bold text-[12px] md:text-[20px]">
            {data?.offer?.days_left}
          </p>
          <div className="bg-[#F4FCFE] p-2 rounded-xl text-[12px] md:text-[16px]">
            <p className="text-[#8591A3] ">
              تاريخ الانتهاء {data?.offer?.expire_date}
            </p>
          </div>
        </div>
        {/* center detalis */}
        <h1>تفاصيل المركز</h1>
        <div className=" block md:flex py-4">
          <div className="  my-2 flex  text-right  w-full md:w-[60%]">
            <SlLocationPin className="ml-2 text-[20px] mt-[2px]" />
            العنوان: {firstAddress || "لا يوجد عنوان"}{" "}
          </div>
          <div className="  my-2 flex text-center   w-full md:w-[40%]">
            <CiPhone className="ml-2 mt-[2px] text-[20px]" />
            الهاتف: {phone || "لا يوجد رقم هاتف"}{" "}
          </div>
        </div>
        <hr className="text-[#BBEDEC]" />
        {/* end center detalis */}
        <div>
          <h1 className=" font-extrabold text-[16px] my-4 ">تفاصيل العرض:</h1>
          <div className="md:flex mr-6 w-full">
            <ul className="list-disc w-fll md:w-[40%]  ">
              <li className="mb-4">
                <span className="text-[#8591A3] font-bold  text-[.9rem] ">
                  {data.offer?.details || "لا يوجد"}
                </span>
              </li>
              <li className="mb-4">
                <p className="text-[#8591A3] font-bold text-[.9rem]">
                  الشروط والأحكام:
                  <span className="text-[#0e2d60] font-normal mr-[2px]">
                    {data.offer?.terms_and_conditions || "لا يوجد"}
                  </span>
                </p>
              </li>
              <li className="mb-4">
                <p className="text-[#8591A3] font-bold text-[.9rem]">
                  قيمة خصم العرض:
                  <span className="text-[#0e2d60] font-normal mr-[2px]">
                    {data.offer?.items[0]?.discount_value}
                    {data.offer?.items[0]?.discount_symbol}{" "}
                  </span>
                </p>
              </li>

              {/* <li className="mb-4">
                <p className="text-[#8591A3] font-bold text-[.9rem] mb-6">
                  تفاصيل العرض:
                  <span className="text-[#0e2d60] font-normal mr-[2px]">
                    {data.offer?.details}
                  </span>
                </p>
              </li> */}
            </ul>
            <ul className="list-disc w-fll md:w-[40%] ">
              <li className="mb-4">
                <p className="text-[#8591A3] font-bold text-[.9rem]">
                  كود العرض :
                  <span className="text-[#0e2d60] font-normal mr-[2px]">
                    {data?.code}
                  </span>
                </p>
              </li>
              <li className="mb-4">
                <p className="text-[#8591A3] font-bold text-[.9rem]">
                  تاريخ صلاحية العرض:
                  <span className="text-[#0e2d60] font-normal mr-[2px]">
                    {data.offer?.expire_date}
                  </span>
                </p>
              </li>
            </ul>
          </div>
          {/* <div className="flex justify-center mt-4 w-full">
            <div className="w-[50%]">
              <button
                onClick={handlePdf}
                className=" ml-4 rounded-xl h-[50px] text-white bg-gradiant font-bold text-[16px] flex items-center justify-center w-[90%] md:w-[70%] "
              >
                <MdOutlineFileDownload className="text-[20px]" />
                العرض PDF
              </button>
            </div>
            <div className="w-[50%]">
              <Link to="#" className="   " onClick={handleCall}>
                <button className="w-[90%] md:w-[70%]   rounded-xl h-[50px] text-[#30ECAC]   font-bold text-[16px] border border-[#30ECAC]">
                  اتصل بالمركز
                </button>
              </Link>
            </div>
          </div> */}
        </div>
      </div>

      {/* <p className="text-[20px] mt-4 text-center font-bold secondary">
        {offerData?.body?.code} كود العرض
      </p> */}
    </div>
  );
};

export default DiscountOrderSuccess;
