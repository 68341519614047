import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "../src/components/context/UserContext";
import { AuthProvider } from "../src/components/context/AuthContext";
import { SharedDataProvider } from "../src/components/context/SharedDataContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "@fortawesome/fontawesome-free/css/all.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <SharedDataProvider>
          <UserProvider>
            <GoogleOAuthProvider clientId="1006340177913-elds8b96llt0c1sr0bkv6fdeaungc0u4.apps.googleusercontent.com">
              <App />
            </GoogleOAuthProvider>
          </UserProvider>
        </SharedDataProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
