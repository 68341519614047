// AuthContext.js
import { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(Cookies.get("authToken"));

  useEffect(() => {
    const tokenFromCookie = Cookies.get("authToken");
    if (tokenFromCookie && !authToken) {
      setAuthToken(tokenFromCookie);
    }
  }, [authToken]);

  const login = (token) => {
    setAuthToken(token);
    Cookies.set("authToken", token);
    Cookies.set("loginTime", new Date().getTime());
  };

  const logout = () => {
    Cookies.remove("authToken");
    Cookies.remove("user");
    window.location.href = "/login"; // Redirect to login page after logging out
  };

  return (
    <AuthContext.Provider value={{ authToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
