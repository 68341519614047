import React from "react";
import axios from "axios";
import config from "../Services/config";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const ApplePayButton = () => {
  const navigate = useNavigate();
  const token = Cookies.get("authToken");

  const baseUrl = config.apiUrl;

  const authorizeMerchant = async (validationURL) => {
    try {
      const response = await axios.post(
        `${baseUrl}/cards/order`,
        {
          amount: 1.15,
          currency: "SAR",
          card_type_id: 1,
          is_apple_pay: 1,
          is_mobile: 0,
          validation_url: validationURL,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            lang: "ar",
          },
        }
      );

      const merchantSessionString = response.data.body.validation_data;
      const orderId = response.data.body.order_id;
      console.log("orderId", orderId);
      const merchantSession = JSON.parse(merchantSessionString);
      return merchantSession;
    } catch (error) {
      console.error("Error validating merchant:", error);
      throw new Error("Merchant validation failed");
    }
  };

  const sendPaymentToBackend = async (paymentToken, orderId) => {
    try {
      const response = await axios.post(
        `${baseUrl}/cards/apple-pay-web`,
        {
          paymentToken,
          orderId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        navigate("/success");
      } else {
        navigate("/fail");
      }
    } catch (error) {
      console.error("Error sending payment to backend:", error);
      navigate("/fail");
    }
  };

  const initiateApplePay = async () => {
    if (window.PaymentRequest) {
      const methodData = [
        {
          supportedMethods: "https://apple.com/apple-pay",
          data: {
            version: 3,
            merchantIdentifier: "merchant.com.mohmsa.mohmweb",
            merchantCapabilities: [
              "supports3DS",
              "supportsCredit",
              "supportsDebit",
            ],
            supportedNetworks: ["visa", "masterCard", "amex"],
            countryCode: "SA",
            currencyCode: "SAR",
          },
        },
      ];

      const details = {
        total: {
          label: "MOHM Web",
          amount: {
            currency: "SAR",
            value: "1.15",
          },
        },
      };

      let paymentResponse;

      try {
        const request = new PaymentRequest(methodData, details);

        request.onmerchantvalidation = async (event) => {
          const validationURL = event.validationURL;
          try {
            const merchantSession = await authorizeMerchant(validationURL);
            event.complete(merchantSession);
          } catch (error) {
            console.error("Merchant validation failed:", error);
            event.complete(null);
          }
        };

        const canMakePayment = await request.canMakePayment();
        if (canMakePayment) {
          paymentResponse = await request.show();

          if (!paymentResponse) {
            console.log("User canceled payment");
            return;
          }
          const paymentToken = paymentResponse.details.token;
          await paymentResponse.complete("success");
          await sendPaymentToBackend(paymentToken, request.orderId);
        } else {
          console.log("User cannot make payments with Apple Pay");
        }
      } catch (error) {
        console.error("Payment processing error:", error.message || error);
        if (paymentResponse) {
          await paymentResponse.complete("fail");
        }
      }
    } else {
      console.log("Payment Request API is not supported in this browser.");
    }
  };

  return (
    <button
      className="w-full mt-4 mx-2 payment-button text-[24px]"
      id="apple-pay-button"
      style={{
        WebkitAppearance: "-apple-pay-button",
        height: "64px",
        backgroundImage:
          "-webkit-named-image(apple-pay-with-text-white, apple-pay-with-text-black)",
        backgroundSize: "100% 60%",
        backgroundOrigin: "content-box",
        backgroundRepeat: "no-repeat",
        padding: "10px",
        width: "50%",
        marginTop: "20%",
      }}
      onClick={initiateApplePay}
    ></button>
  );
};

export default ApplePayButton;
