// Footer.js

import React from "react";
import "./Footer.scss";
import logo from "../../assets/images/logosvg.svg";
import { Link } from "react-router-dom";
import {
  TiSocialTwitter,
  TiSocialFacebook,
  TiSocialInstagram,
} from "react-icons/ti";
import { PiTiktokLogoDuotone } from "react-icons/pi";
import { FaSnapchatGhost } from "react-icons/fa";
const Footer = () => {
  return (
    <footer className="p-2  ">
      <div className="logo-footer drop-shadow-md py-4 ">
        <img src={logo} alt="logo" />
      </div>
      <div className="links-footer container mx-auto pr-14 grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* First Column */}
        <div>
          <h3 className="text-lg font-semibold mb-4">معلومات الاتصال</h3>
          <ul>
            <li>حي الوادي مخرج طريق ابو بكر الصديق رضي الله عنه</li>
            <li>+920013592</li>
            <li>contact@mohmsa.com</li>
            <li>ساعات العمل من 9 صباحا الى 5 مساء من الاحد الى الخميس </li>
          </ul>
        </div>

        {/* Second Column */}
        <div>
          <h3 className="text-lg font-semibold mb-4">روابط سريعة</h3>
          <ul className="w-[180px]">
            <a href="/">
              <li>الرئيسية</li>
            </a>
            <a href="/MedicalNetwork">
              <li>الشبكات الطبية</li>
            </a>
            <a href="/couponsoffers">
              <li>العروض والكوبونات</li>
            </a>
            <a href="/about">
              <li>من نحن</li>
            </a>
            {/* <Link to="/">
              <li>الأسئلة الشائعة</li>
            </Link> */}
            <a href="/contact">
              <li>اتصل بنا</li>
            </a>
            <a target="_blank" href="/terms-conditions">
              <li>الشروط والأحكام </li>
            </a>
          </ul>
        </div>

        {/* Third Column */}
        <div>
          <h3 className="text-lg font-semibold mb-4">نشرة الأخبار</h3>
          <form className="mb-4">
            <input
              type="email"
              placeholder="البريد الالكتروني "
              className=" p-4 w-full drop-shadow-md send-mail  bg-white rounded-full"
            />
            <button
              type="submit"
              className=" subscription-btn text-white px-4 py-2 mt-2 rounded hover:bg-blue-600 transition"
            >
              اشتراك
            </button>
          </form>
          <div className="policy flex py-6">
            <input type="checkbox" name="policy" id="policy" />
            <p className="mr-2">
              أوافق على ان بياناتي المقدمة يتم جمعها وتخزينها لغرض التطوير.
            </p>
          </div>
          <div className="social flex space-x-4">
            <div className="  p-2 rounded-full text-white">
              <Link
                to={"https://x.com/mohmcard?t=3qcncEgJ9T03ZOvGc1WIrg&s=09"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TiSocialTwitter />
              </Link>
            </div>
            <div className="  p-2 rounded-full text-white">
              <Link
                to={"https://www.snapchat.com/add/mohmcard"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaSnapchatGhost />
              </Link>
            </div>
            <div className="  p-2 rounded-full text-white">
              <Link
                to={
                  "https://www.instagram.com/mohmcard?igsh=MWV2dG8ycWRoczM0dw=="
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <TiSocialInstagram />
              </Link>
            </div>
            <div className="  p-2 rounded-full text-white">
              <Link
                to={"https://x.com/mohmcard?t=3qcncEgJ9T03ZOvGc1WIrg&s=09"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PiTiktokLogoDuotone />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
