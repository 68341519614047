import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import registerImg from "../../../assets/images/verify.svg";
import "./Verify.scss";
import axios from "axios";
import config from "../../Services/config";
import notfoundmem from "../../../assets/images/notfoundmem.svg";
import "./NotFound.scss";
const Verify = () => {
  const navigate = useNavigate();
  const [Verified, setVerified] = useState(true);
  const form = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = form;
  const onSubmit = async (data) => {
    const baseUrl = config.apiUrl;
    axios.defaults.baseURL = baseUrl;

    const ApiUrl = `/membership-check`;
    try {
      const response = await axios.post(ApiUrl, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          lang: "ar",
        },
      });
      if (response?.data) {
        // Authentication successful, navigate to the home page or any other route
        navigate("/verfied", { replace: true });
      }
      // console.log(response.data.success);
    } catch (error) {
      setVerified(false);
      reset();
      // navigate("/notfound", { replace: true });
      // console.log(error);
    }
  };
  return (
    <>
      {Verified ? (
        <div className="background-verify-page  pt-[114px] ">
          <div className="mx-auto    flex items-center justify-center  min-h-[800px]    ">
            <div className="w-[auto] ">
              <div className="background-vefiy mt-[100px]">
                <div>
                  <img src={registerImg} alt="" />
                </div>

                <div className="info absolute ">
                  <h1 className="font-black text-center mt-[118px] text-[2rem]">
                    تحقق من العضوية
                  </h1>
                  <p className="font-bold my-[47px] mr-[15px] text-center text-[16px] lg:text-[18px] w-[461px]">
                    للتحقق من العضوية يرجى البحث برقم العضوية (عدد الأرقام يجب
                    أن لا يزيد أو يقل عن 8 )
                  </p>
                  <div className="flex justify-center ">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <input
                        type="text"
                        id="name"
                        className=" membership name-shadow w-[300px] lg:w-[525px] border p-2 rounded input-rigister"
                        placeholder="ادخل رقم العضوية"
                        {...register("membership_id", {
                          required: "هذا الحقل مطلوب",
                          minLength: {
                            value: 8,
                            message: "يجب أن يكون طول النص على الأقل 8 حروف",
                          },
                        })}
                      />

                      {errors.membership_id && (
                        <p className="text-red-500 text-[20px] w-[300px] mt-2">
                          {errors.membership_id.message}
                        </p>
                      )}
                      <div className="membership-verify ">
                        <button
                          type="submit"
                          disabled={!isValid}
                          className="font-bold text-white w-[300px] lg:w-[525px] text-base"
                        >
                          تحقق من العضوية
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="background-verify-page pt-[90px] ">
          <div className="mx-auto    flex items-center justify-center h-screen    ">
            <div className="w-[auto] ">
              <div className="    background-vefiy">
                <div>
                  <img
                    style={{
                      top: "-145px",
                      right: " 135px",
                    }}
                    src={notfoundmem}
                    alt=""
                  />
                </div>
                <div className="info absolute ">
                  <h1 className="font-black text-center mt-[118px] text-[2rem]">
                    لا توجد بطاقه بالبيانات المسجلة
                  </h1>
                  <p className="font-bold my-[47px] mr-[15px] text-center text-[18px] w-[461px]">
                    للتحقق من العضوية يرجى البحث برقم العضوية أو رقم الهوية
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                      type="text"
                      id="name"
                      className=" membership name-shadow w-[525px] border p-2 rounded input-rigister"
                      placeholder="ادخل رقم الهوية او رقم العضوية"
                      {...register("membership_id", {
                        required: "هذا الحقل مطلوب",
                        minLength: {
                          value: 8,
                          message: "يجب أن يكون طول النص على الأقل 8 حروف",
                        },
                      })}
                    />

                    {errors.membership_id && (
                      <p className="text-red-500 text-[20px] w-[300px] mt-2">
                        {errors.membership_id.message}
                      </p>
                    )}
                    <div className="membership-verify ">
                      <button
                        type="submit"
                        disabled={!isValid}
                        className="font-bold text-white w-[525px]  text-base"
                      >
                        تحقق من العضوية
                      </button>
                    </div>
                  </form>
                </div>
                {/* <div className="absolute bottom-0   ">
           
            </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Verify;
