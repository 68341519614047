import React from "react";
import success from "../../../../assets/images/success.png";
import successCenter from "../../../../assets/images/payment-fail.png";
import "./response.scss";
import { Link } from "react-router-dom";
const Fail = () => {
  return (
    <div className="success-contaier w-full h-full mt-[120px] ">
      <div className="flex w-[370px] lg:w-full mx-auto justify-center">
        <img src={success} alt="" />
      </div>
      <div className=" w-[277px] lg:w-[477px] h-auto mx-auto py-8">
        <div className="flex justify-center">
          <img src={successCenter} alt="" />
        </div>
        <div className="mt-[-80px] ">
          <h1 className="text-[18px] font-tajawal lg:text-[28px] text-center my-8 font-bold text-[#FF004F]">
            عملية الدفع لم تتم بنجاح، يرجى المحاولة مرة أخرى
          </h1>
          <Link to={"/"}>
            <button className="btn-success">العودة للصفحة الرئيسية</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Fail;
