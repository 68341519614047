import React from "react";
// import successCoupon from "../../../../assets/images/couponsuccess.png";
import Header from "../../../Layout/Header";
import "./couponsuccess.scss";
import { SlLocationPin } from "react-icons/sl";
import { CiPhone } from "react-icons/ci";
import { Link } from "react-router-dom";
import { MdOutlineFileDownload } from "react-icons/md";
// import logo from "../../../../assets/images/image 91.svg";
import { useLocation } from "react-router-dom";
const CouponSuccess = () => {
  const location = useLocation();
  const { couponData } = location.state || {};
  const data = couponData?.body;
  // console.log(data)

  const handleCall = (e) => {
    e.preventDefault();
    window.location.href = "tel:+1234567890";
  };
  const handlePdf = (e) => {
    e.preventDefault();
    window.location.href = data.pdf_path;
  };
  const firstAddress = data?.coupon?.center?.addresses[0].address;
  const phone = data?.coupon?.center?.addresses[0].phone;
  return (
    <>
      <Header name={"العروض والكوبونات"} />
      <div className=" mx-auto w-[400px] xl:w-[700px] my-8  px-[20px] py-[12px] coupon-success">
        {/* <div className="flex  align-center justify-center pt-[10px] lg:pt-[30px] ">
          <img className="w-[320px] h-[225px]" src={successCoupon} alt="" />
        </div> */}
        <h1 className="text-[16px] md:text-[24px] text-center font-extrabold  mb-4 lg:mb-[50px]">
          تم ارسال الكوبون الى بريدك الالكتروني بنجاح
        </h1>
        <div className="flex justify-between mb-4 pb-[20px] ">
          <div className="flex">
            <img
              className="w-[120px] h-[120px] ml-4"
              src={data?.coupon?.center?.logo}
              alt=""
            />
            <p className="mt-[30px] text-[#41B6C3] text-[12px] md:text-[20px] font-bold">
              {data?.coupon?.center?.name}
            </p>
          </div>
          <div className="text-center mt-[20px]">
            <p className="text-[#30ECAC] font-bold text-[12px] md:text-[20px]">
              {data?.coupon?.days_left}
            </p>
            <div className="md:bg-[#F4FCFE] md:p-2 rounded-xl text-[12px] md:text-[16px]">
              <p className="text-[#8591A3] ">
                تاريخ الانتهاء {data?.coupon?.expire_date}
              </p>
            </div>
          </div>
        </div>

        {/* center detalis */}
        <h1>تفاصيل المركز</h1>
        <div className=" block md:flex py-4">
          <div className="  my-2 flex  text-right  w-full md:w-[60%]">
            <SlLocationPin className="ml-2 text-[20px] mt-[2px]" />
            العنوان: {firstAddress || "لا يوجد عنوان"}{" "}
          </div>
          <div className="  my-2 flex text-center   w-full md:w-[40%]">
            <CiPhone className="ml-2 mt-[2px] text-[20px]" />
            الهاتف: {phone || "لا يوجد رقم هاتف"}{" "}
          </div>
        </div>
        <hr className="text-[#BBEDEC]" />
        {/* end center detalis */}
        <div>
          <h1 className="font-extrabold text-[16px] mt-4 ">تفاصيل الكوبون</h1>
          <div className="md:flex mr-6 w-full">
            <ul className="list-disc w-fll md:w-[40%]  ">
              <li className="mb-4">
                <p className="text-[#8591A3] font-bold text-[.9rem]">
                  الشروط والأحكام:
                  <span className="text-[#0e2d60] font-normal mr-[2px]">
                    {data.coupon?.terms_and_conditions}
                  </span>
                </p>
              </li>
              <li className="mb-4">
                <p className="text-[#8591A3] font-bold text-[.9rem]">
                  قيمة خصم الكوبون:
                  <span className="text-[#0e2d60] font-normal mr-[2px]">
                    {data.coupon?.discount_symbol} {data.coupon?.discount_value}
                  </span>
                </p>
              </li>

              <li className="mb-4">
                <p className="text-[#8591A3] font-bold text-[.9rem]">
                  تفاصيل الكوبون:
                  <span className="text-[#0e2d60] font-normal mr-[2px]">
                    {data.coupon?.details}
                  </span>
                </p>
              </li>
            </ul>
            <ul className="list-disc w-fll md:w-[40%] ">
              <li className="mb-4">
                <p className="text-[#8591A3] font-bold text-[.9rem]">
                  كود الكوبون :
                  <span className="text-[#0e2d60] font-normal mr-[2px]">
                    {data?.code}
                  </span>
                </p>
              </li>
              <li className="mb-4">
                <p className="text-[#8591A3] font-bold text-[.9rem]">
                  تاريخ صلاحية الكوبون:
                  <span className="text-[#0e2d60] font-normal mr-[2px]">
                    {data.coupon?.expire_date}
                  </span>
                </p>
              </li>
            </ul>
          </div>
          <div className="flex justify-center mt-4 w-full">
            <div className="w-[50%]">
              <button
                onClick={handlePdf}
                className=" ml-4 rounded-xl h-[50px] text-white bg-gradiant font-bold text-[16px] flex items-center justify-center w-[90%] md:w-[70%] "
              >
                <MdOutlineFileDownload className="text-[20px]" />
                الكوبون PDF
              </button>
            </div>
            <div className="w-[50%]">
              <Link to="#" className="   " onClick={handleCall}>
                <button className="w-[90%] md:w-[70%]   rounded-xl h-[50px] text-[#30ECAC]   font-bold text-[16px] border border-[#30ECAC]">
                  اتصل بالمركز
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <div className=" mx-auto xl:w-[1209px] my-8  p-8 coupon-success">
        <div className="flex justify-center lg:pt-[72px] ">
          <img src={successCoupon} alt="" />
        </div>
        <h1 className="text-[32px] text-center font-extrabold  mt-[70px] lg:mb-[180px]">
          تم ارسال الكوبون الى بريدك الإلكتروني بنجاح
        </h1>
        <div className="flex justify-between ">
          <div className="flex">
            <img src="" alt="" />
            <p>Lorem ipsum dolor sit.</p>
          </div>
          <div>
            <p>متبقي 5 ايام</p>
            <p>تاريخ الانتهاء 2024-3-25</p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default CouponSuccess;
