import { useState } from "react";
import axios from "axios";
const useApi = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [Loading, setLoading] = useState(true);

  const fetchData = async (url, options = {}) => {
    try {
      // setLoading(true);
      const response = await axios(url, options);

      if (response.status >= 200 && response.status < 300) {
        setData(response.data);
        // console.log(response.data);
        setLoading(false);
      } else {
        throw new Error("Server error");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // Set loading to false whether the request succeeds or fails
    }
  };

  return { data, error, fetchData, Loading };
};

export default useApi;
