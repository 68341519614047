// src/contexts/UserContext.js

import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState(() => {
    const storedUser = Cookies.get("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const setLoggedInUser = (userData) => {
    Cookies.set("user", JSON.stringify(userData));

    const storedUser = JSON.parse(Cookies.get("user"));
    if (storedUser) {
      setUser(storedUser);
    }
  };
  //update user
  const updateUser = (newUserData) => {
    setUser({ ...user, ...newUserData });
    Cookies.set("user", JSON.stringify(newUserData));
  };
  //user logout
  const logout = () => {
    setUser(null);
    Cookies.remove("authToken");

    Cookies.remove("user");
    navigate("/login", { replace: true });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setLoggedInUser,
        logout,
        updateUser,
        setUser,
        userId,
        setUserId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
