import React from "react";
// import menuIcon from "../../assets/images/menuIcon.svg";
import logo from "../../assets/images/logoMobile.svg";
import menuIconlarge from "../../assets/images/offcanvas.svg";
// import { MdPerson } from "react-icons/md";
import { HiOutlineUser, HiLogout } from "react-icons/hi";

import { NavLink, Link } from "react-router-dom";
import {
  TiSocialTwitter,
  TiSocialFacebook,
  TiSocialInstagram,
} from "react-icons/ti";
import { PiTiktokLogoDuotone } from "react-icons/pi";
import { FaSnapchatGhost } from "react-icons/fa";
const Offcanvas = ({ user, logout, isOpen, handleToggle }) => {
  return (
    <div className="flex relative justify-start items-center z-[999999]   ">
      {/* Button to toggle the offcanvas */}

      {/* Offcanvas panel */}
      {isOpen && (
        <>
          {/* Overlay */}
          <div
            className="fixed  top-0 left-0 right-0 bottom-0 bg-black opacity-30  z-10"
            onClick={handleToggle}
          ></div>
          <div className="fixed offcanvas-style top-0 right-0 bottom-0 bg-white w-[254px]   z-50  shadow-lg">
            <div className="relative">
              <div className="flex h-[90px] bg-[#fff]   justify-center items-center ">
                <button
                  className=" absolute right-[26px] top-[40px] ml-[40px] "
                  onClick={handleToggle}
                >
                  <img
                    className="w-[50px] h-[50px]"
                    src={menuIconlarge}
                    alt=""
                  />
                </button>
                <Link onClick={handleToggle} to={"/"}>
                  <img
                    className="absolute right-[83px] top-[40px] ml-[40px] "
                    src={logo}
                    alt=""
                  />
                </Link>
              </div>
              {/* Content */}
              <div className=" w-full mb-[31px]  flex justify-center items-center ">
                {user ? (
                  <div className="bg-[#FAFCFE] font-bold  w-full  ">
                    <p className=" w-full flex justify-center uppercase items-center text-[#263238]  ">
                      {user?.name}
                    </p>
                    <p className=" w-full text-[#263238]  flex justify-center items-center">
                      {user?.phone}
                    </p>
                    <p className=" w-full text-[#263238]  flex justify-center items-center">
                      {user?.membership_id}
                    </p>

                    <Link
                      to="/account"
                      className="w-[184px] mx-auto h-[32px] text-white text-[.9rem] font-bold login-mobile flex justify-center items-center"
                      onClick={handleToggle}
                    >
                      <HiOutlineUser className="text-white ml-1 "></HiOutlineUser>
                      إدارة الحساب
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <ul
                style={{ borderBottom: "1px solid #D2EAEC" }}
                className="w-full flex justify-center items-center  "
              >
                <div className="text-center menu-mob-offcanvas">
                  <li onClick={handleToggle}>
                    <NavLink to="/">الرئيسية</NavLink>
                  </li>
                  <li onClick={handleToggle}>
                    <NavLink to="/couponsoffers">العروض و الكوبونات</NavLink>
                  </li>
                  <li onClick={handleToggle}>
                    <NavLink to="/MedicalNetwork">الشبكة الطبية</NavLink>
                  </li>
                  <li onClick={handleToggle}>
                    <NavLink to="/about">من نحن</NavLink>
                  </li>
                  <li onClick={handleToggle}>
                    <NavLink to="/contact">تواصل معنا</NavLink>
                  </li>
                  <li onClick={handleToggle}>
                    <NavLink to="/membership">تحقق من العضوية</NavLink>
                  </li>
                  {user ? (
                    <li onClick={handleToggle}>
                      <NavLink className="nav-links" to="/orders">
                        طلب البطاقة
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                </div>
              </ul>
              {user ? (
                <div className="flex justify-center items-center">
                  <button
                    className="mt-4 flex justify-center items-center font-semibold text-[#47BECC]"
                    onClick={logout}
                  >
                    <HiLogout className="ml-2" />
                    تسجيل الخروج
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="social-offcanvas flex justify-center w-full items-center">
              <div className="  p-2 rounded-full text-white">
                <Link
                  to={"https://x.com/mohmcard?t=3qcncEgJ9T03ZOvGc1WIrg&s=09"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TiSocialTwitter />
                </Link>
              </div>
              <div className="  p-2 rounded-full text-white">
                <Link
                  to={"https://www.snapchat.com/add/mohmcard"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaSnapchatGhost />
                </Link>
              </div>
              <div className="  p-2 rounded-full text-white">
                <Link
                  to={
                    "https://www.instagram.com/mohmcard?igsh=MWV2dG8ycWRoczM0dw=="
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TiSocialInstagram />
                </Link>
              </div>
              <div className="  p-2 rounded-full text-white">
                <Link
                  to={"https://x.com/mohmcard?t=3qcncEgJ9T03ZOvGc1WIrg&s=09"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PiTiktokLogoDuotone />
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Offcanvas;
