import React from "react";

import notfoundmem from "../../../assets/images/notfoundmem.svg";
// import "./NotFound.scss";
const NotFound = () => {
  return (
    <div className="background-verify-page pt-[90px] ">
      <div className="mx-auto    flex items-center justify-center h-screen    ">
        <div className="w-[auto] ">
          <div className="    background-vefiy">
            <div>
              <img
                style={{
                  top: "-145px",
                  right: " 135px",
                }}
                src={notfoundmem}
                alt=""
              />
            </div>
            <div className="info absolute ">
              <h1 className="font-black text-center mt-[118px] text-[2rem]">
                لا توجد بطاقه بالبيانات المسجلة
              </h1>
              <p className="font-bold my-[47px] mr-[15px] text-center text-[18px] w-[461px]">
                للتحقق من العضوية يرجى البحث برقم العضوية
              </p>
              <input
                type="text"
                id="name"
                className=" membership name-shadow w-[525px] border p-2 rounded input-rigister"
                placeholder="ادخل       رقم العضوية"
              />
              <div className="btn-verify ">
                <button className="font-bold text-white w-[525px] text-base">
                  تحقق من العضوية
                </button>
              </div>
            </div>
            {/* <div className="absolute bottom-0   ">
           
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
