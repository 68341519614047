import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import "./NavBar.scss";
import logo from "../../assets/images/logosvg.svg";
import { useUser } from "../context/UserContext";
import { MdPerson } from "react-icons/md";
import { Dropdown } from "flowbite-react";
import { HiOutlineUser, HiLogout } from "react-icons/hi";
import menuIcon from "../../assets/images/menuIcon.svg";
import { CgProfile } from "react-icons/cg";
// import UpperBar from "../Upperbar/UpperBar.js";
import MohmMobile from "../../assets/images/Mohm-mobile.svg";
import Offcanvas from "./Offcanvas.js";
import { TiBusinessCard } from "react-icons/ti";
const Navbar = () => {
  const { user, logout } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  // console.log(user);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  // console.log(user);
  return (
    <>
      <Offcanvas
        user={user}
        logout={logout}
        className="sm:hidden"
        isOpen={isOpen}
        handleToggle={handleToggle}
      ></Offcanvas>
      <nav className="absolute top-5 left-1/2 transform -translate-x-1/2 z-50 w-full max-w-[1204px]">
        <div className="p-2 nav-container mx-auto w-[376px] h-[48px] md:w-[95%] lg:w-[980px] xl:w-[1204px] shadow-lg flex  items-center justify-between">
          <li className="md:hidden  ">
            <button onClick={handleToggle}>
              <img className="w-[27px] h-[27px] " src={menuIcon} alt="" />
            </button>
          </li>
          <li>
            <Link to={"/"}>
              <img
                src={logo}
                srcSet={`${MohmMobile} 300w, ${logo} 1024w`}
                sizes="(max-width: 640px) 300px, 1024px"
                alt="logo"
                className=" w-[119px]  h-[42px] md:w-[104px] md:h-[42px]"
              />
            </Link>
          </li>
          <ul className=" hidden md:flex items-center nav-bar">
            <>
              <li className="ml-[7px] xl:ml-[21px] text-[.625rem] md:text-[11px] lg:text-[.75rem] xl:text-[15px]">
                <NavLink className="nav-links mr-4  " to="/">
                  الرئيسية
                </NavLink>
              </li>
              <li className="ml-[7px] xl:ml-[21px] text-[0.625rem]  md:text-[11px]  lg:text-[.75rem] xl:text-[15px]">
                <NavLink className="nav-links" to="/couponsoffers">
                  العروض والكوبونات
                </NavLink>
              </li>
              <li className="ml-[7px] xl:ml-[21px] text-[0.625rem] md:text-[11px]  lg:text-[.75rem] xl:text-[15px]">
                <NavLink className="nav-links" to="/MedicalNetwork">
                  الشبكة الطبية
                </NavLink>
              </li>
              <li className="ml-[7px] xl:ml-[21px] text-[0.625rem] md:text-[11px]  lg:text-[.75rem] xl:text-[15px]">
                <NavLink className="nav-links" to="/about">
                  من نحن
                </NavLink>
              </li>
              <li className="ml-[7px] xl:ml-[21px] text-[0.625rem]  md:text-[11px] lg:text-[.75rem] xl:text-[15px]">
                <NavLink className="nav-links" to="/contact">
                  تواصل معنا
                </NavLink>
              </li>
              <li className="ml-[7px] xl:ml-[21px] text-[0.625rem]  md:text-[11px] lg:text-[.75rem] xl:text-[15px]">
                <NavLink className="nav-links" to="/membership">
                  تحقق من العضوية
                </NavLink>
              </li>
              {user ? (
                <li className="ml-[7px] xl:ml-[21px] text-[0.625rem]  md:text-[11px] lg:text-[.75rem] xl:text-[15px]">
                  <NavLink className="nav-links" to="/orders">
                    طلب البطاقة
                    {/* <TiBusinessCard className="hidden  xl:inline mr-[2px] mb-[3px] text-xl " /> */}
                  </NavLink>
                </li>
              ) : (
                ""
              )}
            </>
          </ul>
          {/* Buttons */}

          {user ? (
            <>
              {/* <UpperBar style={{ width: "180px!important" }} /> */}

              <span>
                <Dropdown
                  className=" text-[15px] dropdownContanier  "
                  style={{
                    background:
                      "linear-gradient(94.48deg, #24b9d1 -8.56%, #30ecac 93.91%)",
                    boxShadow: "0px 10px 25px 0px #e9f3f4",
                    height: "43px",
                    width: "auto",
                    fontWeight: "bold",
                    textTransform: "capitalize ",
                    border: 0,
                    borderRadius: "4px",
                    whiteSpace: "nowrap",
                  }}
                  label={user.name}
                >
                  <Dropdown.Header>
                    <span className="block text-sm userLoggedIn ">
                      مرحبا , {user.name}
                    </span>
                    <span className="block truncate text-sm font-medium"></span>
                  </Dropdown.Header>
                  <Dropdown.Item
                    className="w-full flex justify-start"
                    icon={HiOutlineUser}
                  >
                    <Link
                      className=" w-full flex justify-start"
                      to={"/account"}
                    >
                      ادارة الحساب
                    </Link>
                  </Dropdown.Item>

                  {/* <Dropdown.Item icon={HiCog}>الاعدادات</Dropdown.Item> */}
                  <Dropdown.Divider />
                  <Dropdown.Item
                    style={{ color: "#BCCCCD", marginLeft: "2px" }}
                    onClick={logout}
                    icon={HiLogout}
                  >
                    تسجيل الخروج
                  </Dropdown.Item>
                </Dropdown>
              </span>
            </>
          ) : (
            <div className="flex items-center  side-buttons">
              {/* <UpperBar style={{ width: "180px!important" }} /> */}
              <Link
                // to="/joinMedicalNetwork"
                to="./orders"
                className="bg-blue-500 ml-2 w-[123px]  h-[28px] xl:h-[43px]  text-[.625rem] lg:text-[11px] xl:text-[13px] md:w-[135px] p-1 lg:w-[154px] xl:w-[186px] text-white  rounded sign-up"
              >
                طلب البطاقة
                <TiBusinessCard className="hidden  xl:inline mr-2 text-xl " />
              </Link>
              <Link
                to="/login"
                className=" sign-in  h-[28px] xl:h-[43px]   text-[.625rem] lg:text-[11px] xl:text-[13px] md:w-[80px] lg:w-[100px]  xl:w-[150px] text-white px-2 py-2 rounded"
              >
                تسجيل دخول{" "}
                <MdPerson className="hidden  xl:inline mr-2 text-xl " />
              </Link>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
