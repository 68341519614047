import React from "react";
import heading from "../../../assets/images/heading.png";
import clientInfo from "../../../assets/images/client-info.png";
import LoginForm from "../Login/LoginForm";
import "./loginform.scss";
export const RequestCard = () => {
  return (
    <div className="req-card  xl:p-8 xl:mt-[120px]">
      <div className="text-center mx-auto  ">
        <h1 className="text-[#5CDCD0] text-[40px] font-extrabold">
          اطلب البطاقة
        </h1>
        <h4 className="mb-8 font-bold text-[16px] text-[ #A3A6AE]">
          وتمتع بخدماتنا الفريدة من خلال وثيقتك
        </h4>
        <div className="flex justify-center mb-8">
          <img src={clientInfo} alt="" />
        </div>
      </div>

      <div className="mx-auto   Login">
        <LoginForm />
      </div>
    </div>
  );
};
