// SharedDataContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import config from "../Services/config";
const SharedDataContext = createContext();
export const SharedDataProvider = ({ children }) => {
  const [cities, setCities] = useState([]);
  const [SelectedCiyt, setSelectedCiyt] = useState();
  const [districts, setDistricts] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [SelectedDistrict, setSelectedDistrict] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSpecializations, setSelectedSpecializations] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  // console.log("scheck", selectedCheckboxes);

  const [filters, setFilters] = useState({
    city_id: "",
    region_id: "",
    special_ids: "",
    sort_by: "",
    type_id: "",
    keyword: "",
  });
  // console.log("filters", filters);
  const apiUrl = config.apiUrl;
  //fetch specialis
  useEffect(() => {
    // Fetch data from API and set state
    const fetchData = async () => {
      try {
        axios.defaults.baseURL = apiUrl;

        const specializationsResponse = await axios.get(`specials`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            lang: "ar",
          },
        });

        setSpecializations(specializationsResponse.data?.body);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  //fetch districts
  useEffect(() => {
    // Fetch districts data from API
    const fetchDistricts = async () => {
      try {
        axios.defaults.baseURL = apiUrl;

        const response = await axios.get(`regions`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            lang: "ar",
          },
        });
        setDistricts(response.data?.body);
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    };

    fetchDistricts();
  }, []);
  //fetch cities

  useEffect(() => {
    // Fetch cities data based on the selected district
    const fetchCities = async () => {
      if (SelectedDistrict) {
        try {
          axios.defaults.baseURL = apiUrl;

          const response = await axios.get(
            `/regions/${SelectedDistrict}/cities`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                lang: "ar",
              },
            }
          );
          setCities(response.data?.body);
          // console.log(response?.data?.body);
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      }
    };

    fetchCities();
  }, [SelectedDistrict]);
  //update district

  const updateSelectedDistrict = (newDistrict) => {
    setSelectedDistrict(newDistrict);
  };

  //change check box

  const handleCheckboxChange = (optionId) => {
    setFilters((prevFilters) => {
      const isOptionSelected = selectedSpecializations.includes(optionId);

      if (isOptionSelected) {
        // If selected, remove it from the array
        const updatedSpecializations = selectedSpecializations.filter(
          (selected) => selected !== optionId
        );
        setSelectedSpecializations(updatedSpecializations);

        return {
          ...prevFilters,
          special_ids: updatedSpecializations,
        };
      } else {
        // If not selected, add it to the array
        const updatedSpecializations = [...selectedSpecializations, optionId];
        setSelectedSpecializations(updatedSpecializations);

        return {
          ...prevFilters,
          special_ids: updatedSpecializations,
        };
      }
    });
    setSelectedCheckboxes((prevSelected) => {
      if (prevSelected.includes(optionId)) {
        return prevSelected.filter((id) => id !== optionId);
      } else {
        return [...prevSelected, optionId];
      }
    });
  };
  return (
    <SharedDataContext.Provider
      value={{
        cities,
        districts,
        specializations,
        SelectedDistrict,
        updateSelectedDistrict,
        setSelectedDistrict,
        filters,
        setFilters,
        originalData,
        setOriginalData,
        filteredData,
        setFilteredData,
        setSelectedCiyt,
        handleCheckboxChange,
        selectedSpecializations,
        setSelectedSpecializations,
        selectedCheckboxes,
        setSelectedCheckboxes,
      }}
    >
      {children}
    </SharedDataContext.Provider>
  );
};

export const useSharedData = () => {
  const context = useContext(SharedDataContext);
  if (!context) {
    throw new Error("useSharedData must be used within a SharedDataProvider");
  }
  return context;
};
