import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
// import { useUser } from "../context/UserContext";

const RedirectMohm = ({ children }) => {
  const user = useUser();
  if (!user.user) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default RedirectMohm;
